import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { initGestures } from '@/capacitor/initGestures';
import { patchConsoleWarn } from '@/capacitor/patchConsoleWarn';
import { fixSafeArea } from '@/capacitor/fixInsets';
import { handleStatusBarOnOrientationChange } from '@/capacitor/hideStatusBarOnLandscape';
import { initInAppPurchases } from '@/capacitor/inAppPurchases';
import { initCapacitorUpdates } from '@/capacitor/initCapacitorUpdates';
import { initCapacitorMixpanel } from '@/capacitor/mixpanel';
// import { initCapacitorKlaviyo } from '@/capacitor/klaviyo';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { initPushNotifications } from '@/capacitor/pushNotifications';
import { compareVersions } from 'compare-versions';
import SecureApi from '@/flows/Authentication/services/SecureApi';

export const CAPACITOR_CODE_BUNDLE_VERSION_KEY =
    'CAPACITOR_CODE_BUNDLE_VERSION_KEY';

export const initCapacitor = async () => {
    try {
        await initCapacitorUpdates();
        await ScreenOrientation.unlock();
        if (Capacitor.isNativePlatform()) {
            document.documentElement.style.cssText += 'text-select: none;';
        }

        await initCapacitorMixpanel();
        // await initCapacitorKlaviyo();

        const isAndroid = Capacitor.getPlatform().match(/android/i);

        App.addListener('backButton', () => window.history.back());

        await initInAppPurchases(isAndroid);

        // console.log('=== App is ready', bundle);
        // if (bundle.version !== '1.0.484-cp3') {
        //     const version = await CapacitorUpdater.download({
        //         url: 'https://math99.s3.eu-central-1.amazonaws.com/dist-1.0.484-cp3.zip',
        //         version: '1.0.484-cp3',
        //     });
        //     console.log('=== Downloaded version', version);
        //     await CapacitorUpdater.set(version);
        // }

        await handleStatusBarOnOrientationChange(isAndroid);

        await fixSafeArea(isAndroid);

        patchConsoleWarn();

        initGestures();
        // await initPushNotifications();
        console.info('=== Capacitor init complete');
    } catch (err) {
        console.error('=== Error while initializing Capacitor', err);
    }
};

const storeUrls = {
    android: 'https://play.google.com/store/apps/details?id=com.math99.mobile',
    ios: 'https://apps.apple.com/pl/app/99math-master-math-facts/id6472210505',
};

function storeUrl() {
    return storeUrls[Capacitor?.getPlatform()] || '';
}

export async function mobileAppUpdateRequiredFromUrl() {
    if (!Capacitor?.isNativePlatform()) {
        return false;
    }
    try {
        const appInfo = await App.getInfo();
        // Fetch the minimum required versions from the backend
        const {
            data: { minAppVersion },
        } = { data: { minAppVersion: '2.0.0' } }; //await SecureApi().get('/mobile-version-check');

        if (compareVersions(appInfo.version, minAppVersion) < 0) {
            // Show modal prompting user to update
            return storeUrl();
        }
    } catch (error) {
        console.error('Error checking app version:', error);
    }
    return false;
}

window.capApp = {
    async update() {
        const latest = await CapacitorUpdater.getLatest();
        const bundle = await CapacitorUpdater.download(latest);
        CapacitorUpdater.set(bundle);
    },
};
