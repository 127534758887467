<template>
    <div class="swab-wrapper">
        <div class="swab-input-container">
            <input
                v-model="email"
                v-model.trim="$v.email.$model"
                type="text"
                name="email"
                placeholder="Enter admin’s email address"
                class="swab-input"
                :class="{
                    'swab-input__error': !!$v.email.$error,
                }"
                @input="$v.email.$reset()"
                @blur="$v.email.$touch()"
                @keyup.enter="v.email.$touch()"
            />
            <a
                :href="`mailto:${email}?subject=Can%20we%20take%20advantage%20of%20this%2099math%20promotion%3F&body=%0D%0AHi%2C%0D%0A%0D%0AI%E2%80%99ve%20been%20using%2099math%20to%20help%20my%20students%20master%20their%20math%20skills%20and%20they%20love%20it!%20I%20would%20like%20to%20improve%20our%20test%20scores%20and%20get%20students%20even%20more%20excited%20about%20math%20by%20upgrading%20to%2099math%20Premium.%20%0D%0A%0D%0ATheir%20Premium%20version%20would%20unlock%20the%20potential%20of%20all%20of%20our%20students%20with%20personalized%20%26%20adaptive%20math%20practice%2C%20help%20us%20make%20informed%20decisions%20through%20powerful%20reports%2C%20and%20share%20progress%20effortlessly.%0D%0A%0D%0AThey%20are%20offering%20a%20special%20promotion%20for%202024-2025%20Site%20Plans%20and%20I%E2%80%99d%20like%20to%20take%20advantage%20of%20it%20before%20the%20offer%20expires.%20Here%E2%80%99s%20more%20information%3A%20https%3A%2F%2Fpricing.99math.com%2F%0D%0A%0D%0AThank%20you!`"
                target="_blank"
                class="swab-btn"
            >
                {{ loading ? 'Sending email...' : 'Share with admin' }}
            </a>
        </div>
    </div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import { SIMPLE_EMAIL_REGEX } from '@/flows/Authentication/helpers/AuthHelpers';
import SecureApi from '@/flows/Authentication/services/SecureApi';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';
import { mapActions } from 'vuex';

export default {
    props: {
        isGetPremiumModal: {
            type: Boolean,
            default: false,
        },
    },
    validations: {
        email: {
            email: (email) => {
                if (SIMPLE_EMAIL_REGEX.test(email)) {
                    return email.length > 5 && email.length < 100;
                }
                return false;
            },
        },
    },
    data() {
        return {
            $v: useVuelidate(),
            email: '',
            loading: false,
            timeout: null,
        };
    },
    methods: {
        ...mapActions('v2/teacherPremium', [
            'setPremiumModalMode',
            'setShowEmailSentModal',
            'setShowWelcomePremiumModal',
            'setShowPremiumTrialEndModal',
        ]),
        async shareEmail() {
            this.$data.$v.$touch();
            if (!this.$data.$v.$invalid) {
                new TrackingService().track(EVENTS.SEND_PREMIUM_TO_ADMIN, {
                    location: this.$route.path,
                    email: this.email,
                });

                this.loading = true;

                await SecureApi().post('/referrals/subscribe-admins', {
                    emails: [this.email],
                    tag: this.isGetPremiumModal
                        ? 'premium_interested_teacher_2'
                        : 'premium_interested_teacher',
                });

                this.loading = false;

                this.setPremiumModalMode(null);
                this.setShowWelcomePremiumModal(null);
                this.setShowPremiumTrialEndModal(null);
                this.setShowEmailSentModal(true);

                this.email = '';
                this.$data.$v.$reset();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.swab-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.swab-input-container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 6px;

    @media screen and (max-width: $w-max-phone) {
        flex-direction: column;
    }
}
.swab-input {
    width: 250px;
    height: 34px;
    padding: 6px 22px 6px;
    border-radius: 8px;
    border: unset;
    background: #f0efef;

    font-family: $main-font-family;
    color: #000;
    font-size: 16px;
    font-weight: 500;

    @media screen and (max-width: $w-max-desktop) {
        height: 28px;
        padding: 6px 10px 6px;
    }

    &::placeholder {
        opacity: 0.4;
        font-family: $main-font-family;
        color: #000;
        font-size: 16px;
        font-weight: 500;
    }

    &__error {
        height: 30px;
        border: 2px solid red;

        @media screen and (max-width: $w-max-desktop) {
            height: 24px;
        }
    }
}
.swab-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 46px;
    border-radius: 8px;
    border: unset;
    background: var(--Identity-Accent-Primary-CTA, #f08a4f);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;

    color: var(--UI-White, #fff);
    font-size: 16px;
    font-weight: 700;

    @media screen and (max-width: $w-max-desktop) {
        width: 160px;
        height: 40px;
        font-size: 15px;
    }
}
</style>
