import {
    isLandscape,
    isMobileScreenWidth,
    isTabletScreenWidth,
    isMobileDevice,
    isMobileScreenDimensions,
} from '@/core/helpers/MobileHelpers';

export const MobileScreenWidthDetect = {
    data() {
        return {
            isPhoneScreenWidth: isMobileScreenWidth(),
            isTabletScreenWidth: isTabletScreenWidth(),
            isMobileScreenDimensions: isMobileScreenDimensions(),
            isMobile: isMobileDevice(),
            isLandscape: isLandscape(),
        };
    },
    mounted() {
        window.addEventListener('resize', this.onResize);

        screen.orientation?.addEventListener('change', this.onRotate);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);

        screen.orientation?.removeEventListener('change', this.onRotate);
    },
    methods: {
        onResize() {
            this.isPhoneScreenWidth = isMobileScreenWidth();

            this.isTabletScreenWidth = isTabletScreenWidth();

            this.isMobileScreenDimensions = isMobileScreenDimensions();

            // without Orientation API onRotate is not called
            if (!screen.orientation) {
                this.isLandscape = isLandscape();
            }
        },
        onRotate() {
            this.isLandscape = isLandscape();
        },
    },
};
