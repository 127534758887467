import Api from '@/flows/Authentication/services/SecureApi';
import {
    EVENTS,
    TrackingService,
} from '@/core/services/TrackingService/TrackingService';
import moment from 'moment';

/**
 *  Student store module contains all student related
 *  state, getters, mutations and actions.
 */
const store = {
    namespaced: true,
    state: {
        friends: {
            all: [],
            receivedInvites: [],
            sentInvites: [],
            commitedAt: null,
        },
    },
    getters: {
        activeClass: (state, getters) => {
            return getters['v2/user/activeClass'];
        },
        userAvatarData: (state, getters, rootState, rootGetters) => {
            const userData =
                rootGetters.user?.studentInfo?.homeGameV10SimpleTreeSocial;

            if (userData) {
                return {
                    avatar: userData.avatar || null,
                    customAvatar: userData.customAvatar || null,
                    background: userData.background || '#572598',
                    frame: userData.frame || 'no-frame',
                };
            } else {
                return null;
            }
        },
    },
    mutations: {
        setFriendsData: (state, next) => {
            if (next.friends) {
                state.friends.all = next.friends;
            }

            if (next.receivedInvites) {
                state.friends.receivedInvites = next.receivedInvites;
            }

            if (next.sentInvites) {
                state.friends.sentInvites = next.sentInvites;
            }

            state.friends.commitedAt = moment();
        },
        resetFriendsData: (state) => {
            state.friends = {
                all: [],
                receivedInvites: [],
                sentInvites: [],
                commitedAt: null,
            };
        },
    },
    actions: {
        init: async () => {},
        reset: ({ commit }) => {
            commit('resetFriendsData');
        },
        saveCache: (store) => {
            // should save to localStorage
        },
        loadCache: () => {
            // should load from localStorage
        },
        clearCache: () => {
            // should clear localStorage
        },
        fetchAndStoreFriends: async (store) => {
            try {
                if (
                    store.state.friends.commitedAt &&
                    moment().diff(
                        moment(store.state.friends.commitedAt),
                        'seconds',
                    ) < 600
                ) {
                    return store.state.friends;
                }

                const response = await Api().get('friends');

                const { data, success, error } = response.data;

                if (success && data) {
                    await store.commit('setFriendsData', data);

                    new TrackingService().track(EVENTS.STUDENT_FRIENDS_COUNT, {
                        friendsCount: data.friends?.length,
                    });

                    return data;
                } else if (error) {
                    console.error(
                        `[fetchAndStoreFriends] server error: ${error}`,
                    );
                }
            } catch (err) {
                console.error(`[fetchAndStoreFriends] request error: ${err}`);
            }
        },
        claimReward: async (
            store,
            { source = 'battle-pass', sourceId, rewards = [] },
        ) => {
            try {
                const result = await Api().post(`battlepass/rewards/claim`, {
                    source,
                    sourceId,
                    rewards,
                });

                const { success, error, data } = result.data;

                if (success) {
                    store.dispatch(
                        'v2/user/update',
                        { studentInfo: data },
                        { root: true },
                    );

                    return data;
                } else if (error) {
                    console.error(`[claimReward] server error: ${error}`);
                }
            } catch (err) {
                console.error(`[claimReward] request error: ${err}`);
            }
        },
    },
};

export default store;
