import Api from '@/core/services/Api.js';
export default {
    namespaced: true,
    state: () => ({
        channels: {},
    }),
    mutations: {
        setEvtSource(state, { channel, evtSource }) {
            if (channel) state.channels[channel] = { evtSource };
        },
    },
    actions: {
        closeConnection: ({ state, commit }, channel) => {
            console.debug('App SSE connection closed');
            state?.channels?.[channel]?.evtSource?.close();
            commit('setEvtSource', { channel, evtSource: null });
        },
        init: (store, { userId, channel, eventHandler }) => {
            store.dispatch('closeConnection', channel);

            if (!userId) {
                console.warn('SSE - No user id.');

                return false;
            }

            const evtSource = new EventSource(
                `${import.meta.env.VITE_API_URL}sse/${channel}/${userId}`,
            );

            store.commit('setEvtSource', { channel, evtSource });

            evtSource.onmessage = (event) => {
                try {
                    eventHandler(JSON.parse(event.data));
                } catch (error) {
                    console.error(
                        'Not possible to parse event json',
                        error,
                        event,
                    );
                }
            };

            evtSource.onopen = (event) => {
                console.debug('SSE connection opened', event.target);
            };

            evtSource.onerror = (error) => {
                console.debug('SSE connection error', error);
            };
        },
        emitEvent: async (store, { channel, data }) => {
            return Api().post(`sse/emit/${channel}/${data.receiverId}`, data);
        },
    },
};
